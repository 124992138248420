import React from 'react';
import WebDevConnectPageTemplate from '../../../components/Connect/WebConnectPageTemplate';

const subtitles = {
  A: (
    <>
      Tintash is a mobile and web development company that provides managed remote teams from around
      the globe. We work closely with you to design and build your digital products. Our clients
      include several <span className="connect__heading-description-bold">Startups</span>,{' '}
      <span className="connect__heading-description-bold">Unicorns</span> and{' '}
      <span className="connect__heading-description-bold">Fortune 500s.</span>
    </>
  ),
  B: 'Leverage our Web development talent to elevate your project. We help you achieve the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <WebDevConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Web Application Development Services"
      subtitles={subtitles}
      metaTitle="Web Application Development Services - Tintash"
      metaDescription="Our Web Application Development Services partner with your business to create your ideal Web Application with over ten years of experience in Development!"
      pageviewTitle="Connect/Web/Web.Development"
    />
  );
}

export default index;
